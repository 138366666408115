<template>
    <div>
        <div class="mb-3">
            <label for="username" class="form-label"> Email </label>
            <input type="text" class="form-control" v-model="userCopy.username" id="username" />
        </div>
        <div class="mb-3">
            <label class="form-label">
                Numéros de téléphone
                <button
                    class="btn btn-sm btn-primary ms-3"
                    @click="userCopy.userInfos.phoneNumbers.push({ value: '', isNew: true })"
                >
                    Ajouter
                </button>
            </label>
            <div
                v-for="(phoneNumber, i) in userCopy.userInfos.phoneNumbers"
                :key="'phoneNumber-' + i"
                class="input-group mb-3"
            >
                <input
                    type="text"
                    class="form-control"
                    :class="{
                        'border-warning':
                            !user.userInfos.phoneNumbers[i] ||
                            phoneNumber.value !== user.userInfos.phoneNumbers[i].value,
                    }"
                    placeholder="numéro de téléphone"
                    v-model="phoneNumber.value"
                />
                <button
                    class="btn btn-outline-danger"
                    type="button"
                    @click="userCopy.userInfos.phoneNumbers.splice(i, 1)"
                >
                    Supprimer
                </button>
            </div>
        </div>
        <div class="mb-3">
            <label for="lastName" class="form-label">Nom</label>
            <input
                type="text"
                class="form-control"
                v-model="userCopy.userInfos.lastName"
                :class="{ 'border-warning': userCopy.userInfos.lastName !== user.userInfos.lastName }"
                id="lastName"
            />
        </div>
        <div class="mb-3">
            <label for="firstName" class="form-label">Prénom</label>
            <input
                type="text"
                class="form-control"
                :class="{ 'border-warning': userCopy.userInfos.firstName !== user.userInfos.firstName }"
                v-model="userCopy.userInfos.firstName"
                id="firstName"
            />
        </div>
        <div v-if="$store.getters['login/isWorker']">
            <div class="mb-3" v-if="showRolesSelection">
                <label class="form-label" v-if="Object.keys(rolesList).length > 0">Droits</label>
                <div class="form-check form-switch" v-for="(humanRoleName, role) in rolesList" :key="'role-' + role">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        :id="'switch_role_' + role"
                        :checked="userCopy.roles.includes(role)"
                        @click="updateUserRole(role)"
                    />
                    <label class="form-check-label" :for="'switch_role_' + role">{{ humanRoleName }}</label>
                </div>
            </div>
        </div>
        <div v-if="$store.getters['login/isWorker']">
            <div class="mb-3">
                <label for="firstNameProfil" class="form-label">Profil :</label>
                <input
                    disabled
                    type="text"
                    class="form-control"
                    id="firstNameProfil"
                    :placeholder="`${isWorkerAdmin ? 'Manager sous-traitant' : 'Agent sous-traitant'}`"
                />
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <div class="col-lg-10">
                    <multiselect
                        :disabled="parameter"
                        v-model="userCopy.userInfos.agencies"
                        :options="agencies"
                        :multiple="true"
                        placeholder="Nom de l'agence sous-traitante"
                        selectLabel="Cliquez pour sélectionner"
                        deselectLabel=""
                        label="name"
                        track-by="name"
                    >
                    </multiselect>
                </div>
                <div v-if="!parameter">
                    <button class="btn btn-primary" @click="openModalCompany">Agences</button>
                </div>
            </div>
            <div class="mb-3">
                <label for="statut" class="form-label">Statut :</label>
                <input disabled type="text" class="form-control" id="statut" :placeholder="userCopy.status" />
            </div>
            <div class="mb-3">
                <label for="domaine" class="form-label">Domaine :</label>
                <input disabled type="text" class="form-control" id="domaine" placeholder="Travaux" />
            </div>
        </div>
        <div v-if="$store.getters['login/isAdminFunc']">
            <div class="mb-3">
                <label for="greenId" class="form-label">GID :</label>
                <input
                    type="text"
                    class="form-control"
                    v-model="userCopy.greenId"
                    id="greenId"
                    :placeholder="userCopy.greenId"
                />
            </div>
            <div class="mb-3">
                <label for="firstName" class="form-label">Profil :</label>
                <div>
                    <input type="radio" id="one" value="suez" v-model="userCopy.profil" name="profil" />
                    <label for="one">&nbsp;Exploitant Suez</label>
                    <div>
                        <input type="radio" id="two" value="admin_func" v-model="userCopy.profil" name="profil" />
                        <label for="two">&nbsp;Administrateur fonctionnel</label>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="firstName" class="form-label">Statut :</label>
                <div>
                    <input type="radio" id="Actif" value="Actif" v-model="userCopy.status" name="status" />
                    <label for="Actif">&nbsp;Actif</label>
                    <div>
                        <input type="radio" id="Inactif" value="Inactif" v-model="userCopy.status" name="status" />
                        <label for="Inactif">&nbsp;Inactif</label>
                    </div>
                </div>
            </div>
            <div class="mb-3 d-flex justify-content-between">
                <div class="col-lg-10">
                    <multiselect
                        v-model="userCopy.regions"
                        :options="regions"
                        :multiple="true"
                        placeholder="Regions"
                        selectLabel="Cliquez pour sélectionner"
                        deselectLabel=""
                        label="name"
                        track-by="name"
                    >
                    </multiselect>
                </div>
            </div>
        </div>
        <div v-if="userHasDataChanged" class="text-end mt-5">
            <div class="btn-group" role="group">
                <div v-if="$store.getters['login/isWorkerAdmin'] || $store.getters['login/isAdminFunc']">
                    <button
                        v-if="!userRecordInProgress"
                        class="btn btn-primary"
                        @click="userCopy = Object.assign({}, JSON.parse(JSON.stringify(user)))"
                    >
                        {{ cancelText }}
                    </button>
                    &nbsp;
                    <button class="btn btn-warning" @click="userRecord">
                        {{ recordText }}
                        <div v-if="userRecordInProgress" class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                    </button>
                </div>
            </div>
        </div>

        <blank-modal ref="modalCompany" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend"></legend>
            </template>
            <template #modalBody>
                <div class="content">
                    <vue-good-table
                        ref="vueTableAgencies"
                        @on-selected-rows-change="selectionAgencies"
                        :columns="columnAgencies"
                        :rows="companies"
                        :select-options="{
                            enabled: true,
                            selectionText: 'Agences sélectionnées',
                            clearSelectionText: 'effacer la sélection',
                        }"
                        max-height="500px"
                        :fixed-header="true"
                    >
                    </vue-good-table>
                </div>
            </template>
            <template #modalFooterExtra>
                <button class="btn btn-primary" @click="onClickOkAgency">Ok</button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
export default {
    name: 'User',
    props: {
        user: {
            required: false,
            default: () => ({
                id: null,
                username: '',
                roles: [],
                profil: '',
                greenId: '',
                status: '',
                regions: [],
                userInfos: {
                    firstName: '',
                    lastName: '',
                    phoneNumbers: [],
                    company: [],
                    agencies: [],
                },
                accountType: '',
            }),
        },
        userRecordMethod: {
            type: Function,
            required: false,
            default: () => Promise.resolve(),
        },
        recordText: {
            type: String,
            required: false,
            default: () => 'Enregistrer les modifications',
        },
        cancelText: {
            type: String,
            required: false,
            default: () => 'Annuler',
        },
        showPasswordFields: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        showRolesSelection: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        usernameEditable: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        edit: {
            required: false,
            type: Boolean,
            default: () => false,
        },
        parameter: {
            required: false,
            type: Boolean,
            default: () => false,
        },
    },
    data: () => ({
        profil: null,
        statut: null,
        userCopy: {},
        companies: [],
        regions: [],
        companiesFilteredIntermsOf: [],
        agencySelected: [],
        agencies: [],
        isAdmin: false,
        userRecordInProgress: false,
        rolesList: {
            admin: 'Manager Sous-Traitant',
        },
        tempAgencies: [],
        columnAgencies: [
            {
                label: 'Agences',
                field: 'name',
                sortable: true,
                filterOptions: { enabled: true },
            },
            {
                label: 'Société',
                field: 'company.name',
                sortable: true,
                filterOptions: { enabled: true },
            },
        ],
    }),
    watch: {
        user: {
            deep: true,
            immediate: true,
            handler() {
                this.copyUser()
                if (this.edit || this.parameter) {
                    setTimeout(() => {
                        this.userCopy.regions = this.userCopy.regions.map((item) => {
                            return {
                                name: item.code,
                                id: item.id,
                            }
                        })
                        this.userCopy.profil = this.userCopy.accountType === 'agencyAccount' ? 'suez' : 'admin_func'
                        this.userCopy.status = this.userCopy.userStatus.value
                        if (this.userCopy.regions.length === 0) {
                            this.userCopy.regions.push({ name: 'NA', id: 0 })
                        }
                    }, 2)
                }
            },
        },
        agencySelected(val) {
            this.userCopy.userInfos.agency = val
        },
    },
    methods: {
        onClickOkAgency() {
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalCompany.$el).hide()
            this.agencies.push(...this.tempAgencies.filter((it) => !this.agencies.includes(it)))
            this.userCopy.userInfos.agencies = this.agencies.slice()
            this.tempAgencies = []
            this.resetAgenciesSelection()
        },
        openModalCompany() {
            this.companiesFilteredIntermsOf = undefined
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalCompany.$el).show()
        },
        copyUser() {
            this.userCopy = Object.assign({}, JSON.parse(JSON.stringify(this.user)))
            if (!this.user.id) {
                const password = 'password'
                this.userCopy.password = password
                this.userCopy.passwordConfirm = password
            }
            // this.agencies = this.user.userInfos.agencies.slice()
        },
        updateUserRole(role) {
            this.isAdmin = !this.isAdmin
            if (this.userCopy.roles.includes(role)) {
                this.userCopy.roles.splice(this.userCopy.roles.indexOf(role), 1)
            } else {
                this.userCopy.roles.push(role)
            }
        },
        userRecord() {
            this.userRecordInProgress = true

            const payload = JSON.parse(JSON.stringify(this.userCopy))
            payload.regions = this.userCopy.regions.map((c) => c.id)
            payload.userInfos.agency = this.userCopy.userInfos.agencies.map((c) => c.id)
            delete payload.userInfos.agencies
            if (this.$store.getters['login/isWorkerAdmin']) {
                this.userRecordMethod(payload)
                    .then(() => {
                        this.copyUser()
                    })
                    .catch(() => {
                        void 0
                    })
                    .finally(() => {
                        this.userRecordInProgress = false
                    })
            } else {
                this.$http
                    .createUserSuez(payload, this.edit, this.userCopy.id)
                    .then(() => {
                        this.copyUser()
                        this.$router.push({ name: 'users_suez' })
                    })
                    .catch((e) => {
                        this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                    })
                    .finally(() => {
                        this.userRecordInProgress = false
                    })
            }
        },
        companyByAccount() {
            this.$http
                .agencyByAccount(this.$store.state.login.user.userData.workerAccount.id)
                .then((res) => {
                    this.companies = res
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        getRegions() {
            this.$http
                .getRegions()
                .then((res) => {
                    this.regions = res.map((item) => {
                        return {
                            name: item.code,
                            id: item.id,
                        }
                    })
                    this.regions.push({ name: 'NA', id: 0 })
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        selectionAgencies() {
            this.tempAgencies = this.$refs.vueTableAgencies.selectedRows
        },
        resetAgenciesSelection() {
            this.$refs.vueTableAgencies.unselectAllInternal()
        },
    },
    computed: {
        userHasDataChanged() {
            return JSON.stringify(this.user) !== JSON.stringify(this.userCopy)
        },

        isWorkerAdmin() {
            return this.user && this.user.id ? this.user.roles.includes('admin') : this.isAdmin
        },
    },
    created() {
        this.copyUser()
    },
    mounted() {
        if (this.$store.getters['login/isWorker']) {
            this.companyByAccount()
        }
        if (this.$store.getters['login/isAdminFunc']) {
            this.getRegions()
        }
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.content {
    min-height: 200px;
}
</style>
